// import NavBar from './NavBar';
import React, {useEffect, useRef} from 'react';
import TawkMessengerReact from 'components/Tawk';
import TopBar from './TopBar';
import {BottomBar} from 'components/Shared/AppBar';
import {isEmpty} from 'lodash';
import {LEVEL} from '../../constants/user.constant';
import {makeStyles} from '@material-ui/core';
import {Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const {REACT_APP_TAWK_PROPERTY_ID} = process.env;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.main,
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url("/assets/images/bg-body.jpeg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100vh',
    overflow: 'auto',
    paddingTop: 64,
    paddingBottom: '50vw',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '50vw',
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 200,
    },
  },
}));

const DashboardLayout = () => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const tawkMessengerRef = useRef();

  // const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const currentUser = useSelector(state => state?.auth?.currentUser);

  // useEffect(() => {
  //   if (currentUser?.user_pass) {
  //     getUserBalance(dispatch, currentUser?.user_pass?.username);
  //     getUserPoint(dispatch, currentUser?.uuid);
  //   }
  // }, [currentUser?.user_pass, currentUser?.uuid]);

  const handleHideWidget = () => {
    tawkMessengerRef.current.hideWidget();
  };

  const handleShowWidget = () => {
    // console.log('show widget!');
    tawkMessengerRef.current.showWidget();
  };

  // eslint-disable-next-line no-unused-vars
  const handleTawkError = error => {
    // console.log('Tawk error: ', error);
  };

  const setVisitorData = () => {
    // console.log('setVisitorData');

    const playerDetail = {
      name: currentUser.name,
      email: `${currentUser.user_pass.username.toLowerCase()}@gmail.com`,
      Phone: currentUser.phone,
      phone: currentUser.phone,
      username: currentUser.user_pass.username,
      bankName: currentUser.user_bank.bankName,
      bankId: currentUser.user_bank.bankId,
      level: LEVEL[currentUser.level].NAME,
      point: currentUser.user_credit.point,
    };

    tawkMessengerRef.current.visitor(playerDetail, handleTawkError);

    tawkMessengerRef.current.setAttributes(playerDetail, handleTawkError);

    tawkMessengerRef.current.addTags([window.location.host], handleTawkError);
  };

  useEffect(() => {
    if (tawkMessengerRef.current && !isEmpty(window.Tawk_API)) {
      if (!isEmpty(currentUser)) {
        setVisitorData();
      }
      // console.log('isChatHidden', tawkMessengerRef.current?.isChatHidden());
      // console.log('isChatOngoing ', tawkMessengerRef.current?.isChatOngoing());
      // console.log('getWindowType ', tawkMessengerRef.current?.getWindowType());
      if (!isEmpty(currentUser) && tawkMessengerRef.current?.isChatHidden()) {
        handleShowWidget();
      }
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      {/*<TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />*/}
      <TopBar tawkHideWidget={handleHideWidget} />
      {REACT_APP_TAWK_PROPERTY_ID && !isEmpty(currentUser) && (
        <TawkMessengerReact
          propertyId={REACT_APP_TAWK_PROPERTY_ID}
          widgetId="1gpstfjtg"
          ref={tawkMessengerRef}
          // embedId={`tawk_${REACT_APP_TAWK_PROPERTY_ID}`}
        />
      )}
      {/*<NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />*/}
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Outlet />
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default DashboardLayout;
