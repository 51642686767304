import api from '../api/api';
import axios from 'axios';
import {cashOut, changePassword, credit, detail, level, login, point, setting} from '../api/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchAuthLogIn = createAsyncThunk('auth/fetchLogIn', async (arg, {rejectWithValue}) => {
  try {
    const response = await api(login(arg));
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const fetchUserDetail = createAsyncThunk(
  'users/fetchDetail',
  async (arg, {getState, signal, rejectWithValue}) => {
    const state = await getState();
    const uuid = state.user.profile.uuid;
    if (!uuid) return rejectWithValue({});

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await api(detail({uuid}, {cancelToken: source.token}));
    return response.data;
  }
);

export const fetchUserPoint = createAsyncThunk('users/fetchPoint', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(point({uuid}, {cancelToken: source.token}));

  return response.data;
});

export const fetchUserCredit = createAsyncThunk('users/fetchCredit', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const {balanceType} = arg || 'M';
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(credit({username, balanceType}, {cancelToken: source.token}));

  return response.data;
});

export const fetchUserCashOut = createAsyncThunk('users/fetchCashOut', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(cashOut({username, ...arg}, {cancelToken: source.token}));

  return response.data;
});

export const fetchUserSetting = createAsyncThunk(
  'users/fetchSetting',
  async (arg, {getState, signal, rejectWithValue}) => {
    const state = await getState();
    const uuid = state.user.profile.uuid;
    if (!uuid) return rejectWithValue({});

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await api(setting({uuid}, {cancelToken: source.token}));

    return response.data;
  }
);

export const fetchUserChangePassword = createAsyncThunk('users/fetchChangePassword', async (arg, {getState}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const response = await api(changePassword({uuid, ...arg}));
  return response.data;
});

export const fetchUserLevel = createAsyncThunk('users/fetchLevel', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(level({uuid}, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  // [fetchAuthLogIn.pending]: (state, action) => {
  //   state.profile = {...action.payload, loading: true};
  // },
  // [fetchAuthLogIn.fulfilled]: (state, action) => {
  //   const {user, token, success} = action.payload;
  //   if (success) {
  //     setAuthorizationToken(token, user);
  //     state.profile = {...user, loading: false};
  //   }
  // },
  [fetchUserDetail.pending]: state => {
    state.profile = {...state.profile, loading: true};
  },
  [fetchUserDetail.fulfilled]: (state, action) => {
    state.profile = {...action.payload?.data, loading: false};
  },
  [fetchUserPoint.pending]: state => {
    state.profile = {...state.profile, loading: true};
  },
  [fetchUserPoint.fulfilled]: (state, action) => {
    state.profile = {
      ...state.profile,
      user_credit: action.payload?.data,
      loading: false,
    };
  },
  [fetchUserCredit.pending]: (state, action) => {
    const {requestId} = action.meta;
    state.credit = {
      loading: true,
      currentRequestId: requestId,
    };
  },
  [fetchUserCredit.fulfilled]: (state, action) => {
    const {requestId} = action.meta;
    const {loading, currentRequestId} = state.credit;
    if (loading && currentRequestId === requestId) {
      state.credit = {
        ...action.payload,
        loading: false,
        currentRequestId: undefined,
      };
    }
  },
  [fetchUserCredit.rejected]: (state, action) => {
    const {requestId} = action.meta;
    const {loading, currentRequestId} = state.credit;
    if (loading && currentRequestId === requestId) {
      state.credit = {
        ...state.credit,
        loading: false,
        error: action.error,
        currentRequestId: undefined,
      };
    }
  },
  [fetchUserSetting.pending]: (state, action) => {
    state.setting = {...action.payload, loading: true};
  },
  [fetchUserSetting.fulfilled]: (state, action) => {
    state.setting = {...action.payload, loading: false};
  },
  [fetchUserSetting.rejected]: (state, action) => {
    state.setting = {error: action.error, loading: false};
  },
  [fetchUserCashOut.pending]: (state, action) => {
    state.cashOut = {...action.payload, loading: true};
  },
  [fetchUserCashOut.fulfilled]: (state, action) => {
    state.cashOut = {...action.payload, loading: false};
  },
  [fetchUserCashOut.rejected]: (state, action) => {
    state.cashOut = {error: action.error, loading: false};
  },
  [fetchUserLevel.pending]: (state, action) => {
    state.level = {...action.payload, loading: true};
  },
  [fetchUserLevel.fulfilled]: (state, action) => {
    state.level = {...action.payload, loading: false};
    state.profile = {
      ...state.profile,
      user_credit: {
        ...state.profile.user_credit,
        level: action.payload?.data?.level,
      },
    };
  },
};

const name = 'user';
const initialState = {
  token: null,
  profile: {},
  credit: {},
  cashOut: {},
  affiliate: {},
  setting: {},
  level: {},
};

export const userSlice = createSlice({
  name,
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setCredit: (state, action) => {
      state.credit = {
        ...state.credit,
        data: {
          ...action.payload,
        },
      };
    },
    setPoint: (state, action) => {
      state.profile = {...state.profile, user_credit: {...action.payload}};
    },
    setSetting: (state, action) => {
      state.setting = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {setProfile, setCredit, setPoint, setSetting, reset} = userSlice.actions;

export default userSlice.reducer;
