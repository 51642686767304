import {DefaultButton} from 'components/Shared/Button';
import {FaExclamationCircle} from 'react-icons/fa';
import {setModal} from 'store/global.slice';
import {SimpleModal} from 'components/Shared/Modal';
import {useDispatch, useSelector} from 'react-redux';

const ErrorModal = () => {
  const dispatch = useDispatch();
  const isModal = useSelector(state => state.global?.modal);
  const error = useSelector(state => state.global?.error);

  const handleClick = () => {
    dispatch(setModal(false));
  };

  return (
    <SimpleModal
      isOpen={isModal}
      onClose={handleClick}
      body={
        <div className="text-center">
          <div className="flex flex-col items-center space-y-4">
            <div className="py-4">
              <FaExclamationCircle style={{fontSize: 32, color: '#c53030'}} />
            </div>
            <div className="text-xl">{error.title || 'เกิดข้อผิดพลาด'}</div>
            <div>{error?.message}</div>
          </div>
          <div className="pt-4">
            <DefaultButton onClick={handleClick}>ตกลง</DefaultButton>
          </div>
        </div>
      }
    />
  );
};

export default ErrorModal;
