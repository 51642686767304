// import AffiliateView from './views/affiliate/AffiliateView';
// import ChangePasswordView from './views/setting/ChangePasswordView';
import ContactView from './views/contact/ContactView';
// import CreditView from './views/reward/CreditView';
import DashboardLayout from './layouts/DashboardLayout';
// import GamesList from './views/games/GameList';
// import GamesView from './views/games';
import HomePromotion from './views/home/Promotion';
import LiveScoreView from './views/home/LiveScoreView';
import MainLayout from './layouts/MainLayout';
import NotFoundView from './views/errors/NotFoundView';
import PromotionView from './views/promotion/PromotionView';
import React, {lazy} from 'react';
import {PublicLayout} from './layouts/PublicLayout';
// import RegisterView from './views/auth/RegisterView';
// import RewardView from './views/reward/RewardView';
import TableScoreView from './views/home/TableScoreView';
// import WithdrawView from './views/withdraw/WithdrawView';
import {get} from 'lodash';
import {Navigate} from 'react-router-dom';

const Home = lazy(() => import('containers/Home'));
const Dashboard = lazy(() => import('./containers/Dashboard'));
const GameCategory = lazy(() => import('./containers/Game/Category'));
const GameSlot = lazy(() => import('./containers/Game/Slot'));
const Deposit = lazy(() => import('./containers/Deposit'));
const Withdraw = lazy(() => import('./containers/Withdraw'));
const Contact = lazy(() => import('./containers/Contact'));
const TransactionHistory = lazy(() => import('./containers/TransactionHistory'));
const WinLost = lazy(() => import('./containers/WinLost'));
const Affiliate = lazy(() => import('./containers/Affiliate'));
const Reward = lazy(() => import('./containers/Reward'));
const ChangePassword = lazy(() => import('./containers/Settings/ChangePassword'));
const ManageAddress = lazy(() => import('./containers/Settings/ManageAddress'));
const Register = lazy(() => import('./containers/Register'));
const UserLevel = lazy(() => import('./containers/User/Level'));
const Media = lazy(() => import('./containers/Media'));
const Wheel = lazy(() => import('./containers/Media/Wheel'));
const Movie = lazy(() => import('containers/Media/Movie'));
const FlipCard = lazy(() => import('containers/Media/FlipCard'));
const Maintenance = lazy(() => import('containers/Maintenance'));
const Login = lazy(() => import('./containers/Login'));

/**
 * Helper function to conditionally render Maintenance component
 */
const getElement = (status, element) => {
  if (status === 'MA') {
    return <Maintenance />;
  } else {
    return element;
  }
};

/**
 * Helper function to generate elements for authenticated routes
 */
const authElement = status => {
  return [
    {path: 'dashboard', element: getElement(status, <Dashboard />)},
    {path: 'deposit', element: getElement(status, <Deposit />)},
    {path: 'withdraw', element: getElement(status, <Withdraw />)},
    {path: 'affiliate', element: getElement(status, <Affiliate />)},
    {path: 'winlost', element: getElement(status, <WinLost />)},
    {path: 'transaction/history', element: getElement(status, <TransactionHistory />)},
    {path: 'reward', element: getElement(status, <Reward />)},
    {path: 'reward/:categoryId', element: getElement(status, <Reward />)},
    // {path: 'wheel', element: <WheelView />)},
    {path: 'settings/change-password', element: getElement(status, <ChangePassword />)},
    {path: 'settings/manage-address', element: getElement(status, <ManageAddress />)},
    {path: 'user/ranking', element: getElement(status, <UserLevel />)},
    {path: 'contact', element: getElement(status, <Contact />)},
    {
      path: 'media',
      element: getElement(status, <Media />),
      // children: [
      //   {path: 'wheel', element: <Wheel />},
      //   {path: 'movie', element: <Movie />},
      //   {path: 'promotion', element: <PromotionView />},
      // ],
    },
    {path: 'media/wheel', element: getElement(status, <Wheel />)},
    {path: 'media/movie', element: getElement(status, <Movie />)},
    {path: 'media/flip-card', element: getElement(status, <FlipCard />)},
    {path: 'media/promotion', element: getElement(status, <PromotionView />)},
    {path: 'games/category', element: getElement(status, <GameCategory />)},
    {path: 'games/:type/:slot', element: getElement(status, <GameSlot />)},
    {path: '', element: <Navigate to="dashboard" />},
    {path: '*', element: <Navigate to="/404" />},
  ];
};

/**
 * Helper function to generate elements for static (unauthenticated) routes
 */
const staticElement = status => {
  return [
    {path: 'login', element: status === 'MA' ? <Maintenance /> : <Login />},
    {path: 'register', element: status === 'MA' ? <Maintenance /> : <Register />},
    {path: 'regis/:team/:marketing', element: status === 'MA' ? <Maintenance /> : <Register />},
    {path: 'regiss/:team/:marketing', element: status === 'MA' ? <Maintenance /> : <Register />},
    {path: 'aff/:afCode', element: status === 'MA' ? <Maintenance /> : <Register />},
    {path: 'contact', element: status === 'MA' ? <Maintenance /> : <ContactView />},
    {path: 'live-score', element: status === 'MA' ? <Maintenance /> : <LiveScoreView />},
    {path: 'score', element: status === 'MA' ? <Maintenance /> : <TableScoreView />},
    {path: 'promotion', element: status === 'MA' ? <Maintenance /> : <HomePromotion />},
    {path: '/', element: status === 'MA' ? <Navigate to="ma" /> : <Home />},
    {path: 'ma', element: <Maintenance />},
    {path: '*', element: <Navigate to="/404" />},
    {path: '404', element: <NotFoundView />},
  ];
};

/**
 * Helper function to determine the appropriate root element based on status and authentication
 */
const getAuthElement = (status, isLoggedIn) => {
  if (status === 'MA') {
    return <Navigate to="ma" />;
  } else {
    if (isLoggedIn) {
      return <DashboardLayout />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

// Main route configuration
const routes = (isLoggedIn, operation, hasVipDomain) => {
  const status = get(operation, 'websiteStatus', '');

  if (status === 'MA') {
    return [
      {
        path: 'auth',
        element: <DashboardLayout />,
        children: authElement(status),
      },
      {
        path: '',
        element: hasVipDomain ? <PublicLayout /> : <MainLayout />,
        children: staticElement(status),
      },
    ];
  }

  return [
    {
      path: 'auth',
      element: getAuthElement(status, isLoggedIn),
      children: authElement(status),
    },
    {
      path: '',
      element: isLoggedIn ? <Navigate to="auth" /> : hasVipDomain ? <PublicLayout /> : <MainLayout />,
      children: staticElement(status),
    },
  ];
};

export default routes;
