import AppBar from '@material-ui/core/AppBar';
import Container from 'components/Shared/Container';
import Fab from '@material-ui/core/Fab';
import get from 'lodash/get';
import Menu from '../../Menu';
import nav from 'configs/nav.config';
import Toolbar from '@material-ui/core/Toolbar';
import WithdrawBottom from 'bridges/BottomBar/WithdrawBottom';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  appBar: {
    borderTop: `2px solid ${theme.palette.background.main}`,
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.black,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -50,
    left: 0,
    right: 0,
    margin: '0 auto',
    width: 90,
    height: 90,
    backgroundColor: theme.palette.background.black,
    backgroundImage: `url("/assets/images/top/150x150-G.png")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
}));

const BottomBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const gameCategory = useSelector(state => get(state, 'game.gameCategory'));

  const handleFab = () => {
    navigate(`${nav.GAME_CATEGORY.TO}?cat=${gameCategory}`);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container disableGutters>
        <Toolbar disableGutters>
          <Menu
            edge="start"
            ariaLabel={nav.DASHBOARD.TITLE}
            to={nav.DASHBOARD.TO}
            img={nav.DASHBOARD.IMG}
            height={70}
          />
          <Menu edge="start" ariaLabel={nav.DEPOSIT.TITLE} to={nav.DEPOSIT.TO} img={nav.DEPOSIT.IMG} height={70} />
          <Fab
            aria-label="go to game"
            disableRipple
            disableFocusRipple
            className={classes.fabButton}
            onClick={handleFab}>
            <div />
          </Fab>
          <div className={classes.grow} />
          <WithdrawBottom />
          <Menu edge="end" ariaLabel={nav.CONTACT.TITLE} to={nav.CONTACT.TO} img={nav.CONTACT.IMG} height={70} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default BottomBar;
