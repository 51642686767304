import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';
import {Helmet} from 'react-helmet';

const Page = forwardRef(({children, title = '', ...rest}, ref) => {
  return (
    <div
      ref={ref}
      {...rest}
      style={{
        backgroundImage: `url("/assets/images/bg-body.jpeg")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        // backgroundColor: '#161A26',
        // paddingLeft: '150px',
        // paddingRight: '150px',
      }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Page.displayName = 'Page';

export default Page;
