export default {
  DEPOSIT: {
    TITLE: 'deposit',
    TO: '/auth/deposit',
    IMG: '/assets/images/top/150x150-deposit.png',
  },
  WITHDRAW: {
    TITLE: 'withdraw',
    TO: '/auth/withdraw',
    IMG: '/assets/images/top/150x150-withdraw.png',
  },
  USER_LANKING: {
    TITLE: 'ranking',
    TO: '/auth/user/ranking',
  },
  MEDIA: {
    TITLE: 'media',
    TO: '/auth/media',
    IMG: '/assets/images/top/150x150-media.png',
  },
  MEDIA_WHEEL: {
    TITLE: 'wheel',
    TO: '/auth/media/wheel',
  },
  MEDIA_PROMOTION: {
    TITLE: 'promotion',
    TO: '/auth/media/promotion',
  },
  MEDIA_MOVIE: {
    TITLE: 'movie',
    TO: '/auth/media/movie',
  },
  FLIP_CARD: {
    TITLE: 'flip card',
    TO: '/auth/media/flip-card',
  },
  GAME_CATEGORY: {
    TITLE: 'game category',
    TO: '/auth/games/category',
    IMG: '/assets/images/dashboard/600x150-all-game.png',
  },
  DASHBOARD: {
    TITLE: 'dashboard',
    TO: '/auth/dashboard',
    IMG: '/assets/images/top/150x150-home.png',
  },
  CONTACT: {
    TITLE: 'contact',
    TO: '/auth/contact',
    IMG: '/assets/images/top/150x150-contact.png',
  },
};
