import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import Container from '../../components/Shared/Container';
import Logo from '../../components/Logo';
import Menu from '../../components/Shared/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {AppBar, Box, Drawer, makeStyles, Toolbar} from '@material-ui/core';
import {LOGO_MAX_HEIGHT, TOP_MENUS} from 'constants/top.constant';
import {Link as RouterLink} from 'react-router-dom';

// const buttons = [
//   {
//     title: 'คาสิโน',
//     href: 'casino',
//   },
//   {
//     title: 'สล็อต',
//     href: 'slot',
//   },
//   {
//     title: 'กีฬา',
//     href: 'sport',
//   },
//   {
//     title: 'โปรโมชั่น',
//     href: 'promotion',
//   },
//   {
//     title: 'ติดต่อ',
//     href: 'contact',
//   },
// ];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: 110,
    backgroundImage: `url("/assets/images/top/bg.jpg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  toolbar: {
    display: 'flex',
    padding: '0 64px 0 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: LOGO_MAX_HEIGHT[process.env.REACT_APP_CLIENT_ID] || LOGO_MAX_HEIGHT['DEFAULT'],
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuText: {
    color: theme.palette.background.dark,
    fontWeight: '700',
    whiteSpace: 'pre-wrap',
    fontSize: 20,
  },
  button: {
    display: 'flex',
    width: '128px',
    color: theme.palette.background.dark,
    background: '#F5D16E',
    backgroundImage: '-webkit-linear-gradient(top, #F5D16E, #B38220)',
    borderRadius: '20px',
    fontSize: '40px',
    fontWeight: 100,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#B38220',
      backgroundImage: '-webkit-linear-gradient(top, #B38220, #F5D16E)',
    },
  },
}));

// <Button className={classes.button} aria-haspopup="true" color="inherit" variant="contained" href="/contact">
//             <ContactIcon />
//             <Typography style={{marginLeft: 5}} variant="h5">
//               ติดต่อสอบถาม
//             </Typography>
//           </Button>

function HideOnScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({target: window ? window() : undefined});

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const TopBar = props => {
  const {className, ...rest} = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <HideOnScroll {...props}>
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
        <Toolbar>
          <Container maxWidth="lg">
            {/*<Grid container direction="row" className={classes.toolbar}>*/}
            {/*  <Grid item xs={12} md={4}>*/}
            {/*    <RouterLink to="/">{renderLogo()}</RouterLink>*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={12} md={8}>*/}
            {/*    <Box className={classes.menu}>*/}
            {/*      {buttons.map(button => (*/}
            {/*        <Button variant="contained" className={classes.button} key={button.title} href={button.href}>*/}
            {/*          <Typography className={classes.menuText}>{button.title}</Typography>*/}
            {/*        </Button>*/}
            {/*      ))}*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <div className="flex justify-center items-center w-full relative">
              <div className="lg:hidden absolute text-white right-0" onClick={handleOpen}>
                <MenuIcon
                  className="text-primary border border-primary bg-[#0d0d0d] rounded-md"
                  style={{fontSize: 30}}
                />
              </div>
              <Drawer anchor="right" open={open} onClose={handleClose}>
                <Box sx={{width: 'auto', maxWidth: '300px'}}>
                  <div className="text-right px-3 pt-2">
                    <CloseIcon className="text-primary" style={{fontSize: 25}} onClick={handleClose} />
                  </div>
                  <div className="grid grid-cols-1 px-3 py-3 gap-3">
                    {TOP_MENUS.map(({name, to, img, disabled}) => (
                      <Menu
                        key={name}
                        ariaLabel={name}
                        to={to}
                        img={img}
                        disabled={disabled}
                        handleClose={handleClose}
                        className="w-32 h-12"
                      />
                    ))}
                  </div>
                </Box>
              </Drawer>

              <div className="flex justify-start w-full md:w-6/12">
                <RouterLink to="/">
                  <Logo className={classes.logo} />
                </RouterLink>
              </div>
              <div className="flex justify-around w-6/12 hidden md:inline-flex">
                {TOP_MENUS.map(({name, to, img, disabled}) => (
                  <Menu key={name} ariaLabel={name} to={to} img={img} disabled={disabled} className="w-32 h-12" />
                ))}
              </div>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
