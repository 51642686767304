import affiliate from './affiliate.slice';
import announcement from './announcement.slice';
import authReducer from './reducers/authReducer';
import checkIn from './checkIn.slice';
import deposit from './deposit.slice';
import flipCard from './flipCard.slice';
import game from './game.slice';
import gameEvent from './gameEvent.slice';
import global from './global.slice';
import log from './log.slice';
import register from './register.slice';
import reward from './reward.slice';
import wheel from './wheel.slice';
import winlost from './winlost.slice';

// import otherReducer from './reducers/otherReducer';
// import rewardReducer from './reducers/rewardReducer';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import user from './user.slice';
import withdraw from './withdraw.slice';
import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
// import {createLogger} from 'redux-logger';

// const logger = createLogger({
//   predicate: () => process.env.NODE_ENV !== 'production',
//   duration: true,
// });

const appReducer = combineReducers({
  global,
  user,
  auth: authReducer,
  deposit,
  withdraw,
  // reward: rewardReducer,
  // other: otherReducer,
  game,
  affiliate,
  winlost,
  reward,
  log,
  wheel,
  register,
  flipCard,
  gameEvent,
  announcement,
  checkIn,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    setAuthorizationToken();
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const preloadedState = {
  user: {
    // token: authUtil.tokenValue,
    profile: JSON.parse(localStorage.getItem('imiUser')) || {},
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
});

export default store;
