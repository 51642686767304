import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    borderRadius: 10,
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SimpleModal = props => {
  const {body, isOpen, onClose, ...rest} = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    isOpen ? setOpen(true) : setOpen(false);
  }, [isOpen]);

  return (
    <div>
      {body && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          {...rest}>
          <div style={getModalStyle()} className={classes.modal}>
            {body}
          </div>
        </Modal>
      )}
    </div>
  );
};

export {SimpleModal};
